<template>
  <PopoverRoot v-if="currentUser">
    <PopoverTrigger
      as="button"
      data-testid="test-header-user-icon"
      class="std-focus-tight flex size-8 flex-none items-center justify-center rounded-full font-medium hover:bg-gray-100"
      :class="{
        'border-4 border-white bg-blue-default text-xs leading-normal text-white hover:text-gray-900 focus:border-white':
          hasPicture
      }"
    >
      <UiUserAvatar :user="currentUser" />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        as="section"
        side="bottom"
        :side-offset="5"
        class="z-50 w-[300px] rounded-xl bg-gray-200 drop-shadow-xl will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade"
      >
        <header
          class="flex items-center whitespace-nowrap rounded-t-xl bg-blue-default p-4 text-white"
        >
          <UiIcon name="dpa-id" class="mr-2 w-5 text-white" />
          {{ currentUser.givenName + ' ' + currentUser.familyName }}
        </header>
        <div class="flex items-center justify-between p-3">
          <a
            :href="dpaIdUrl"
            target="_blank"
            class="mr-12 inline-block text-blue-default hover:no-underline"
            >dpa ID verwalten</a
          >
          <button
            class="rounded-3xl bg-gray-700 px-3 py-2 text-sm text-white transition duration-200 ease-in-out hover:bg-gray-800"
            @click="handleLogout"
          >
            Abmelden
          </button>
        </div>
        <PopoverArrow class="fill-blue-default" />
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>

<script setup lang="ts">
import {
  PopoverContent,
  PopoverPortal,
  PopoverRoot,
  PopoverTrigger,
  PopoverArrow
} from 'radix-vue'
import { UiIcon } from '@dpa-id-components/dpa-shared-components'

const { $auth } = useNuxtApp()
const config = useRuntimeConfig()
const { data: currentUser } = useAsyncData('currentUser', $auth.getCurrentUser)

const hasPicture = !!currentUser?.value?.picture

const { onLogout } = useApollo()

const handleLogout = async () => {
  await $auth.logout()
  await onLogout()
}

const dpaIdUrl =
  config.public.appContext === 'production'
    ? 'https://sso.dpa-id.de/useradmin?service=Rubix-Editor'
    : 'https://sso.dpa-id.de/useradmin?service=Rubix-Editor-Dev'
</script>
