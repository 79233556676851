import { type MaybeElementRef } from '@vueuse/core'

/**
 * Triggers a hover effect when the mouse is over the element.
 * The hover effect is removed after a delay when the mouse leaves the element.
 */
export default function useStaggeredHover(
  elementRef: MaybeElementRef,
  mouseoutDelay = 300
) {
  const { isOutside } = useMouseInElement(elementRef)
  const isHover = ref(!isOutside.value)

  let timeoutId: ReturnType<typeof setTimeout> | null = null

  watch(
    isOutside,
    value => {
      if (timeoutId) clearTimeout(timeoutId)
      if (value) {
        timeoutId = setTimeout(() => {
          isHover.value = false
        }, mouseoutDelay)
      } else {
        isHover.value = true
      }
    },
    {
      immediate: true
    }
  )

  return isHover
}
