import { type RubixUser } from '~/types'

export enum AvatarSize {
  XSMALL = 'XS',
  SMALL = 'SM',
  MEDIUM = 'md',
  LARGE = 'LG'
}

export type AvatarUser = {
  initials?: string | null
  picture?: string | null
} & Pick<RubixUser, 'accountId' | 'givenName' | 'familyName'>
