const useSidebar = () => {
  const open = useLocalStorage('sidebarOpen', false)

  const toggleOpen = () => {
    open.value = !open.value
  }

  return {
    open,
    toggleOpen
  }
}

export default useSidebar
