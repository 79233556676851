<template>
  <div :class="classObjMain" class="relative">
    <img
      v-if="user.picture && !imagePathNotValid && imageAvatarValid"
      :title="fullName"
      :src="user.picture || ''"
      :alt="fullName"
      class="rounded-full"
      @error="handleInvalidImage"
    />

    <div
      v-else
      data-testid="test-user-initials"
      :class="classObjInitials"
      :title="fullName"
      class="flex h-full w-full items-center justify-center rounded-full bg-blue-default lowercase text-white"
    >
      {{ user.initials ?? concatenatedInitials }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { AvatarSize, type AvatarUser } from './types'

type Props = {
  user: AvatarUser
  size?: AvatarSize
}

const props = withDefaults(defineProps<Props>(), {
  size: AvatarSize.MEDIUM
})

const fullName = computed<string>(() => {
  return `${props.user.givenName} ${props.user.familyName}`
})

const imagePathNotValid = ref(false)
const imageAvatarValid = computed(() => {
  if (!props.user.picture) return false
  if (props.user.picture.includes('https://cdn.auth0.com/avatars/'))
    return false

  return true
})
const classObjMain = computed<Record<string, boolean>>(() => {
  const sz = props.size
  return {
    'w-4 h-4': sz === AvatarSize.XSMALL,
    'w-5 h-5': sz === AvatarSize.SMALL,
    'w-6 h-6': sz === AvatarSize.MEDIUM,
    'w-8 h-8': sz === AvatarSize.LARGE
  }
})

const classObjInitials = computed<Record<string, boolean>>(() => {
  const sz = props.size
  return {
    'text-xxs': sz === AvatarSize.XSMALL || sz === AvatarSize.SMALL,
    'text-xs': sz === AvatarSize.MEDIUM || sz === AvatarSize.LARGE
  }
})

const handleInvalidImage = () => {
  imagePathNotValid.value = true
}

const concatenatedInitials = computed<string>(() => {
  const firstInitial = props.user.givenName.toLowerCase().charAt(0)
  const lastInitial = props.user.familyName.toLowerCase().charAt(0)
  return firstInitial + lastInitial
})
</script>
